<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-container">
        <div class="modal-header">
          <slot name="header">
            <div @click="$router.replace('/')" class="icon">
              <ModalCloseIcon :dark="true" />
            </div>
          </slot>
        </div>

        <div class="modal-body">
          <slot name="body">
            <div class="content">
              <div class="logo">
                <img src="../assets/logo-n.svg" alt="Nvoko Logo" />
              </div>
              <h2>Sign Up</h2>
              <h3 v-if="!invitation">
                <!-- Let's get to know each other. -->
                Nvoko is currently invite only.
              </h3>
              <div class="container max-w-3xl mx-auto" v-else>
                <h3>
                  Complete your registration.
                </h3>
                <div class="inputs">
                  <div class="form-group names">
                    <input
                      v-model="firstName"
                      id="first_name"
                      type="text"
                      class="text-input dark"
                      placeholder="First name"
                    />
                    <input
                      v-model="lastName"
                      id="last_name"
                      type="text"
                      class="text-input dark"
                      placeholder="Last name"
                    />
                  </div>
                  <div class="form-group">
                    <input
                      v-model="email"
                      id="email"
                      type="email"
                      class="text-input dark"
                      placeholder="Email address"
                    />
                  </div>
                  <!-- <div class="w-full form-group" v-if="invitation">
                    <div class="w-full search dark">
                      <div class="icon">
                        <SearchIcon :dark="true" />
                      </div>
                      <input
                        v-model="talentName"
                        type="search"
                        name="search"
                        id="search"
                        placeholder="Enter your artist name"
                        autocomplete="off"
                        class="flex-1"
                      />
                    </div>
                    <div
                      v-if="talentName && spotifySearchResults.length > 0"
                      class="search-results"
                    >
                      <div
                        @scroll="handleSpotifySearchScroll($event)"
                        class="wrapper"
                      >
                        <template v-if="spotifySearchResults.length > 0">
                          <div
                            class="result"
                            :class="{ selected: artist.id === spotifyId }"
                            v-for="artist in spotifySearchResults"
                            :key="artist.id"
                            @click="selectSpotifyArtist(artist)"
                          >
                            <div class="avatar">
                              <img :src="artist.image" />
                            </div>
                            <span class="flex-1 name">{{ artist.name }}</span>
                          </div>
                        </template>
                        <template v-else>
                          <div class="result">
                            <span class="name"
                              >No results found, you can continue for now and
                              we'll figure this out later.</span
                            >
                          </div>
                        </template>
                      </div>
                    </div>
                  </div> -->
                  <div class="form-group">
                    <input
                      v-model="password"
                      id="password"
                      type="password"
                      class="text-input dark"
                      placeholder="Password"
                    />
                  </div>
                  <ul class="unstyled centered">
                    <li>
                      <input
                        v-model="acceptedTerms"
                        class="styled-checkbox"
                        id="styled-checkbox-1"
                        type="checkbox"
                        value="true"
                      />
                      <label for="styled-checkbox-1">
                        I agree with terms and conditions and I’m over the age
                        of 18. I understand Nvoko is a contract generation
                        platform and is not a law firm and is not licensed to
                        practice law. If you have any legal questions, please
                        consult with a music attorney.
                      </label>
                    </li>
                  </ul>
                </div>
                <button
                  @click="onSignUpClick()"
                  class="primary"
                  :disabled="!enableSignupButton || isLoading"
                >
                  {{ isLoading ? "Loading..." : "Create account" }}
                </button>
              </div>
            </div>
          </slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import ModalCloseIcon from "@/assets/icons/Modal-Close.vue";
import SearchIcon from "@/assets/icons/Search.vue";

import REGISTER from "@/api/mutations/REGISTER.gql";
import REGISTER_INVITED_CONTACT from "@/api/mutations/REGISTER_INVITED_CONTACT.gql";
import GET_INVITED_USER_FROM_TOKEN from "@/api/queries/GET_INVITED_USER_FROM_TOKEN.gql";
import gql from "graphql-tag";
const SPOTIFY_SEARCH_ARTISTS = gql`
  query spotify_search_artists($limit: Int!, $offset: Int!, $query: String!) {
    spotify_search_artists(limit: $limit, offset: $offset, query: $query) {
      id
      image
      name
      url
    }
  }
`;
export default {
  name: "Signup",
  components: { ModalCloseIcon, SearchIcon },
  data() {
    return {
      firstName: null,
      lastName: null,
      talentName: null,
      email: null,
      password: null,
      acceptedTerms: false,
      isLoading: false,
      token: null,
      spotifyId: null,
      spotifySearchOffset: 0,
      spotifySearchResults: [],
      spotifySearchLimit: 10
    };
  },
  computed: {
    invitation() {
      return this.$route.query.invitation;
    },
    enableSignupButton() {
      if (
        !this.firstName ||
        !this.lastName ||
        !this.password ||
        !this.acceptedTerms
      )
        return false;

      if (!this.invitation && !this.email) return false;

      return true;
    }
  },
  methods: {
    async onSignUpClick() {
      try {
        this.isLoading = true;
        await this.register();
      } catch (e) {
        alert(e.message || e);
      } finally {
        this.isLoading = false;
      }
    },
    async register() {
      if (!this.invitation) {
        const result = await this.$apollo.mutate({
          mutation: REGISTER,
          variables: {
            email: this.email.trim(),
            first_name: this.firstName.trim(),
            last_name: this.lastName.trim(),
            password: this.password
          }
        });
        const { register } = result.data;
        this.$token = register.token;
        this.$store.commit("account/authSuccess", register);
        this.$router.push({ name: "SignupVerification" });
      } else {
        const result = await this.$apollo.mutate({
          mutation: REGISTER_INVITED_CONTACT,
          variables: {
            token: this.invitation,
            email: this.email,
            first_name: this.firstName.trim(),
            last_name: this.lastName.trim(),
            talent_name: this.talentName?.trim() || null,
            spotify_id: this.spotifyId || null,
            password: this.password
          }
        });
        const { register_invited_contact } = result.data;
        this.$token = register_invited_contact.token;
        this.$store.commit("account/authSuccess", register_invited_contact);
        this.$router.push({ name: "Songs" });
      }
    },
    selectSpotifyArtist(artist) {
      this.spotifyId = artist.id;
    },
    async handleSpotifySearchScroll(event) {
      const { offsetHeight, scrollTop, scrollHeight } = event.target;
      if (offsetHeight + scrollTop >= scrollHeight) {
        this.spotifySearchOffset = this.spotifySearchOffset + 10;
        await this.searchSpotify();
      }
    },
    async searchSpotify() {
      const result = await this.$apollo.query({
        query: SPOTIFY_SEARCH_ARTISTS,
        variables: {
          limit: this.spotifySearchLimit,
          offset: this.spotifySearchOffset,
          query: this.talentName
        }
      });
      const { spotify_search_artists } = result.data;
      this.spotifySearchResults = [
        ...this.spotifySearchResults,
        ...spotify_search_artists
      ];
    }
  },
  async mounted() {
    this.token = this.$route.query.invitation;
    await this.$apollo.queries.getUserFromToken.fetch();
  },
  apollo: {
    getUserFromToken: {
      query: GET_INVITED_USER_FROM_TOKEN,
      variables() {
        return {
          token: this.token
        };
      },
      result({ data }) {
        console.log(data);
        if (data.get_invited_user_from_token.success) {
          this.email = data.get_invited_user_from_token.email;
        }
      },
      skip() {
        return !this.token;
      }
    }

    // subscription: {
    //   query: GET_SUBSCRIPTION,
    //   update: ({ stripe_get_subscription }) => stripe_get_subscription
    // }
  },
  watch: {
    talentName: async function(query) {
      this.spotifySearchOffset = 0;
      this.spotifySearchResults = [];
      if (query) {
        await this.searchSpotify();
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/styles/_mixins.scss";

.modal-container {
  width: 100%;
  height: 100%;
  border-radius: 0;
  margin: 0;
}

.search {
  flex: 1;
  display: flex;
  align-items: center;
  width: 100%;
  /*  */
  justify-content: center;
  // margin-bottom: 16px;
  background: $gray;
  border-radius: 38px;
  padding-left: 16px;

  .icon {
    width: 20px;
    display: flex;
  }

  &.dark {
    background: $text-additional;

    input {
      color: $white;
      &::placeholder {
        color: $white;
      }
    }
  }

  input {
    @include body-light(16px);
    padding: 12px 24px 12px 0px;
    border: none;
    min-width: 0px;
    background: transparent;
    @apply flex-1;

    &:focus {
      outline: none;
    }

    &.small-text {
      font-size: 0.875rem;
    }
  }
}

.modal-header {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  padding: 32px;
}

.modal-body {
  width: 100%;
  height: calc(100vh - 164px);
  padding: 0 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  .content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .logo {
      margin-bottom: 32px;
    }
    h2 {
      color: $white;
      margin-bottom: 16px;
    }
    h3 {
      color: $white;
      font-weight: 300;
      margin-bottom: 32px;
    }
    .inputs {
      width: 100%;
      margin-bottom: 32px;
      .form-group {
        margin-bottom: 24px;
        input {
          @include details-light();
          color: $white;
        }
        span {
          font-weight: 500;
          font-size: 16px;
          line-height: 17px;
          letter-spacing: -0.22px;
          color: #fcfffc;
        }
      }
      .names {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        input {
          width: 48%;
        }
      }
      .styled-checkbox {
        position: absolute;
        opacity: 0;

        & + label {
          display: flex;
          position: relative;
          cursor: pointer;
          padding: 0;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 17px;
          letter-spacing: -0.22px;
          color: #fcfffc;
        }

        & + label:before {
          content: "";
          margin-top: 2px;
          margin-right: 8px;
          display: inline-block;
          vertical-align: text-top;
          width: 14px;
          height: 14px;
          aspect-ratio: 1/1;
          background: transparent;
          border: 1px solid white;
        }

        &:focus + label:before {
          box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
        }

        &:checked + label:before {
          background: transparent;
        }

        &:disabled + label {
          color: #b8b8b8;
          cursor: auto;
        }

        &:disabled + label:before {
          box-shadow: none;
          background: #ddd;
        }

        &:checked + label:after {
          content: "";
          position: absolute;
          left: 2px;
          top: 6px;

          width: 2px;
          height: 2px;
          margin-top: 2px;
          background: white;
          box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white,
            4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
          transform: rotate(45deg);
        }
      }
    }
    button {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.22px;
      padding: 10px 30px 12px;
    }
  }
}

.search-results {
  width: 100%;
  height: 180px;
  background: $text;
  border-radius: 20px;
  overflow: hidden;
  display: flex;
  padding: 16px 16px 16px 0;
  margin-top: 16px;

  .wrapper {
    overflow-y: scroll;
    width: 100%;
    scrollbar-width: thin;
    scrollbar-color: #adadad rgba(#adadad, 0.3);

    &::-webkit-scrollbar {
      /* width of the entire scrollbar */
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      /* color of the tracking area */
      @include background-opacity(#adadad, 0.3);
      border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb {
      /* color of the scroll thumb */
      background-color: #adadad;
      /* roundness of the scroll thumb */
      border-radius: 5px;
    }

    .result {
      display: flex;
      align-items: center;
      padding: 4px 4px 4px 20px;
      cursor: pointer;

      &:hover,
      &.selected {
        @include background-opacity($accent, 0.1);
      }

      .avatar {
        width: 30px;
        height: 30px;

        img {
          border-radius: 50%;
          aspect-ratio: 1/1;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .name {
        @include category-medium();
        color: $white;
        font-weight: bold;
        margin: 0 8px;
      }

      .info {
        @include details-light();
        color: $white;
      }
    }
  }
}
</style>
